import React from "react";
import Select, { Props as SelectProps } from "./select";
import InputBase from "./input-base";
/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props extends SelectProps {}

const FilterSelect: React.FC<Props> = ({ inputProps, ...others }) => {
  return <Select input={<InputBase {...inputProps} />} {...others} />;
};

export default FilterSelect;
