import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
/* eslint-disable @typescript-eslint/no-empty-interface */
interface Props {
  needItem?: number;
}

const HomeSkeleton = React.forwardRef<HTMLDivElement, Props>(
  ({ needItem = 20 }, ref) => {
    const [arr] = React.useState<number[]>(() => {
      const r = [];
      for (let i = 1; i <= needItem; i++) {
        r.push(i);
      }
      return r;
    });
    return (
      <>
        {arr.map((i) => (
          <div
            key={i}
            className="flex flex-col shadow-lg rounded-2xl relative overflow-hidden"
            ref={i === 1 ? ref : null}
          >
            <div className="p-4">
              <div className="w-full">
                <Skeleton
                  variant="rect"
                  width={"100%"}
                  height={"auto"}
                  style={{ padding: "100% 0 0 0" }}
                />
              </div>
              <div className="w-full mt-3 flex flex-col">
                <h3 className="text-lg font-bold line-clamp-1">
                  <Skeleton variant="text" />
                </h3>
                <h4 className="text-xs text-grey-80 line-clamp-1">
                  <Skeleton variant="text" />
                </h4>
              </div>
              <div className="w-full mt-3">
                <Skeleton variant="rect" width={"100%"} height={"2rem"} />
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
);

HomeSkeleton.displayName = "HomeSkeleton";
export default HomeSkeleton;
