import React from "react";

function useOnScreen(ref: { current: HTMLElement | null }, rootMargin = "0px") {
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      { rootMargin }
    );

    const referenceEl = ref.current;

    if (referenceEl) {
      observer.observe(referenceEl);
    }
    return () => {
      if (referenceEl) {
        observer.unobserve(referenceEl);
      }
    };
  }, [ref.current]);

  return isIntersecting;
}

export default useOnScreen;
