import React from "react";
import classNames from "classnames";
import get from "lodash/get";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from "@material-ui/core/MenuItem";

import FilterSelect, { Props as FilterSelectProps } from "./filter-select";

/* eslint-disable @typescript-eslint/no-empty-interface */
interface Props extends FilterSelectProps {
  options: { label: string; value: string }[];
}

const SOCIAL_ICON = {
  facebook: "/assets/sidemenu-logo/facebook-icon-rounded.webp",
  facebook_group: "/assets/sidemenu-logo/small-icon-group.svg",
  tiktok: "/assets/sidemenu-logo/tiktok-icon.svg",
  instagram: "/assets/sidemenu-logo/instagram-icon.svg",
  youtube: "/assets/sidemenu-logo/youtube-icon.svg",
};

const useMenuItemStyle = makeStyles({
  root: {
    position: "relative",
    height: "2.125rem",
    display: "flex",
    justifyContent: "center",
    "&:focus": {
      background: "#f5f7fa",
      "& .MuiListItemIcon-root": {
        color: "#808080",
      },
      "& .MuiListItemText-primary": {
        color: "#808080",
      },
    },
    "&.Mui-selected": {
      background: "#f5f7fa",
    },
    "&:hover": {
      background: "#f5f7fa",
    },
  },
  hidden: {
    display: "none",
  },
});

interface ItemProps extends Omit<MuiMenuItemProps, "button"> {}
const SelectItem = React.forwardRef<HTMLLIElement, ItemProps>(
  ({ hidden, ...others }, ref) => {
    const classes = useMenuItemStyle();
    return (
      <MuiMenuItem
        ref={ref}
        classes={{
          root: classNames(classes.root, hidden ? classes.hidden : ""),
        }}
        {...others}
      />
    );
  }
);

const COLORS = {
  default: {
    color: "#4d4d4d",
    background: "#f5f7fa",
  },
  facebook: {
    color: "#005ed0",
    background: "#e6ebf3",
  },
  facebook_group: {
    color: "#005ed0",
    background: "#e6ebf3",
  },
  tiktok: {
    color: "#000",
    background: "#f5f7fa",
  },
  instagram: {
    color: "#ff9500",
    background: "#fff5e7",
  },
  youtube: {
    color: "#f53126",
    background: "#ffeced",
  },
};

const useInputStyle = makeStyles<Theme, { color: string; background: string }>({
  root: {
    fontSize: "0.75rem",
    color: (props) => props.color,
    backgroundColor: (props) => props.background,
    borderRadius: "0.75rem",
    "& .MuiInputBase-input": {
      paddingRight: "0",
      paddingBottom: "9px",
      paddingTop: "9px",
      "&:hover": {
        background: (props) => props.background,
      },
      "&:focus": {
        background: (props) => props.background,
      },
    },
  },
  disabled: {
    "& .MuiSelect-icon": {
      display: "none",
    },
  },
});

SelectItem.displayName = "SelectItem";
const SocialSelect: React.FC<Props> = ({ options, ...others }) => {
  const [value] = React.useState<string>(options[0].value || "");
  const themeColor = get(COLORS, [value], get(COLORS, "default"));
  const inputClasses = useInputStyle({ ...themeColor });
  return (
    <FilterSelect
      disableNative={true}
      options={options}
      disabled={options.length < 2}
      inputProps={{
        value,
        /* eslint-disable @typescript-eslint/no-empty-function */
        onChange: () => {},
        classes: {
          root: inputClasses.root,
          disabled: inputClasses.disabled,
        },
      }}
      onClick={(e) => e.stopPropagation()}
      renderChild={(option) => {
        return (
          <SelectItem
            key={option.value}
            value={option.value}
            hidden={option.value === value}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="flex items-center px-0 pr-4 sm:pr-0 justify-center text-xs font-medium"
              style={{ color: get(COLORS, [option.value, "color"]) }}
            >
              <span className="w-4 h-4 mr-1">
                <img
                  className="w-full"
                  src={get(SOCIAL_ICON, [option.value], "")}
                />
              </span>
              {option.label}
            </div>
          </SelectItem>
        );
      }}
      {...others}
    />
  );
};

export default SocialSelect;
