import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Sort: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <g
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          stroke="#C6C6C6"
          d="M5.5 2.5v6m10-6v6m-5-6v4m-5 5v6m10-6v6m-5-8v8"
        />
        <path stroke="#979797" d="M3.5 11.5h4m6 0h4m-9-5h4" />
      </g>
    </SvgIcon>
  );
};
export default Sort;
