import React from "react";
import { isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import get from "lodash/get";
import Image from "next/image";
import SocialSelect from "./social-select";
import Button from "./button";
import Contact from "./Dialog/contact";
import ComingSoon from "components/Dialog/AlertModal";
import { gsap } from "gsap";
import { IProfile } from "features/profile/type";
import { mergeRef, nFormatter, joinWithSeperator, defaultBio } from "lib/utils";

interface AvatarProps {
  src: string;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
}

const SOCIAL_UNIT = {
  facebook: "Follower",
  instagram: "Follower",
  tiktok: "Follower",
  youtube: "Subscriber",
  facebook_group: "Member",
  default: "Follower",
};

const _sortSocial = (
  socials: any[],
  platform: string[],
  mainPlatform: string
) => {
  const isAll = get(platform, [0], "") === "all";
  if (isAll) {
    return socials
      .map((i) => ({
        value: get(i, "platform"),
        label: `${nFormatter(get(i, "no_followers", 0), 1)} ${get(
          SOCIAL_UNIT,
          get(i, "platform", "default")
        )}`,
      }))
      .sort((i) => (i.value === mainPlatform ? -1 : 0));
  }
  const selectedPlatform = socials
    .filter((i) => platform.indexOf(i.platform) >= 0)
    .sort((a, b) => b.no_followers - a.no_followers)
    .map((i) => ({
      value: get(i, "platform"),
      label: `${nFormatter(get(i, "no_followers", 0), 1)} ${get(
        SOCIAL_UNIT,
        get(i, "platform", "default")
      )}`,
    }));

  const othersPlatform = socials
    .filter((i) => platform.indexOf(i.platform) === -1)
    .map((i) => ({
      value: get(i, "platform"),
      label: `${nFormatter(get(i, "no_followers", 0), 1)} ${get(
        SOCIAL_UNIT,
        get(i, "platform", "default")
      )}`,
    }));
  return [...selectedPlatform, ...othersPlatform];
};

const Avatar: React.FC<AvatarProps> = ({ src, onMouseEnter, onMouseLeave }) => {
  return (
    <div
      className="w-full h-auto relative"
      style={{ padding: "100% 0 0 0" }}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <Image
        className="absolute top-0 left-0 w-full h-auto rounded-xl filter drop-shadow-xl"
        layout="fill"
        src={src}
        placeholder="blur"
        blurDataURL="/assets/sidemenu-logo/app-icon-1-1.png"
      />
    </div>
  );
};

interface Props extends IProfile {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  platform: string[];
  restorationRef?: React.RefObject<HTMLDivElement> | null;
  restorationCallback?: () => void;
}

const ProfileCard: React.FC<Props> = ({
  influzeeID,
  avatar: headerImage,
  fullName,
  job,
  province,
  socials = [],
  bio,
  categories,
  coverVideo,
  onClick,
  mainPlatform,
  platform,
  contactPhone,
  contactEmail,
  restorationRef,
  restorationCallback,
}) => {
  const router = useRouter();
  const showCategories = categories.slice(0, 3);
  const hiddenCategories = categories.length - 3;
  const avatarWrapperRef = React.useRef<HTMLDivElement>(null);
  const backImageWrapperRef = React.useRef<HTMLDivElement>(null);
  const cardFront = React.useRef<HTMLDivElement>(null);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const descriptionRef = React.useRef<HTMLDivElement>(null);
  const [
    animationTimeline,
    setAnimationTimeline,
  ] = React.useState<gsap.core.Timeline>(() => gsap.timeline());
  const [cardWidth, setCardWidth] = React.useState<number>(0);
  const [cardHeight, setCardHeight] = React.useState<number>(0);
  const [isShowVideo, setIsShowVideo] = React.useState<boolean>(false);
  const imageWrapper = React.useRef<HTMLDivElement>(null);
  const socialOptions = _sortSocial(socials, platform, mainPlatform);
  const glowBackImage = React.useCallback(
    (delayTime = 0) => {
      if (isMobile) return;
      animationTimeline.clear();
      animationTimeline
        .set(backImageWrapperRef.current, { zIndex: 20 }, delayTime)
        .to(
          backImageWrapperRef.current,
          { opacity: 1, duration: 0.8 },
          delayTime
        )
        .to(cardFront.current, { opacity: 0, duration: 0.8 }, delayTime)
        .add(function () {
          if (imageWrapper.current && coverVideo) {
            setIsShowVideo(true);
          }
        });
    },
    [animationTimeline]
  );
  const hideBackImage = React.useCallback(
    (delayTime = 0) => {
      if (isMobile) return;
      animationTimeline.clear();
      animationTimeline
        .set(backImageWrapperRef.current, { zIndex: 0 }, delayTime)
        .to(
          backImageWrapperRef.current,
          { opacity: 0, duration: 0.8 },
          delayTime / 2
        )
        .to(cardFront.current, { opacity: 1, duration: 0.8 }, delayTime)
        .add(function () {
          if (imageWrapper.current && coverVideo) {
            setIsShowVideo(false);
          }
        });
    },
    [animationTimeline]
  );

  const onBackButtonClick = React.useCallback((e, action) => {
    e.stopPropagation();
    if (action === "call") {
      Contact({
        influzeeID,
        fullName: fullName,
        avatar: headerImage,
        phone: contactPhone,
        email: contactEmail,
      });
    }
    if (action === "chat") {
      /* router.push("/chat"); */
      const { close } = ComingSoon({
        type: "coming-soon",
        title: "Coming Soon",
        description: "Chức năng này đang được phát triển",
        bottomText: "Uh! OK nha",
        onBottomClick: () => {
          close();
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current?.offsetWidth);
      setCardHeight(cardRef.current?.offsetHeight);
    }
    function updateBackImageSize() {
      if (cardRef.current) {
        setCardWidth(cardRef.current?.offsetWidth);
        setCardHeight(cardRef.current?.offsetHeight);
      }
    }
    window.addEventListener("resize", updateBackImageSize);
    return () => window.removeEventListener("resize", updateBackImageSize);
  }, []);

  /* Restore lại vị trí đã scroll trước đó */
  React.useEffect(() => {
    // restorationRef is only provided to the ProductCard that needs to be scrolled to
    // Restoring scroll here ensures the previously selected product will always be restored, no matter how long the API request
    // to get products takes
    if (restorationRef && restorationRef.current) {
      restorationRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
      setTimeout(() => {
        if (restorationCallback && typeof restorationCallback === "function") {
          restorationCallback(); // TODO: Vì swr làm list bị re-render nhiều lần nên cần timeout - optimize sau
        }
      }, 1000);
    }
  });

  return (
    <div
      className="sm:shadow-lg sm:rounded-2xl flex flex-row cursor-pointer relative overflow-hidden"
      ref={mergeRef<HTMLDivElement>(cardRef, restorationRef)}
      onClick={onClick}
    >
      <div className="z-10 sm:p-4 flex flex-col w-full" ref={cardFront}>
        <div className="w-full" ref={avatarWrapperRef}>
          <Avatar
            src={headerImage}
            onMouseEnter={glowBackImage.bind(null, 0)}
            onMouseLeave={hideBackImage.bind(null, 0)}
          />
        </div>
        <div className="w-full mt-3 flex flex-col">
          <h3 className="text-lg font-bold line-clamp-1">{fullName}</h3>
          <h4 className="text-xs text-grey-80 line-clamp-1">
            {joinWithSeperator(" | ", job.join(", "), province)}
          </h4>
        </div>
        <div className="w-full mt-3">
          {socials.length > 0 && (
            <SocialSelect options={socialOptions} height={"13rem"} />
          )}
        </div>
        {!isMobile && (
          <div className="flex flex-row flex-1">
            <div className="flex flex-col w-full">
              <p
                className="w-full mt-3 line-clamp-2 text-grey-80 text-xs flex-1"
                ref={descriptionRef}
              >
                {bio || defaultBio(fullName)}
              </p>
              <div className="mt-3 flex flex-nowrap overflow-hidden relative h-fit-content">
                {showCategories.map((i, index) => (
                  <div
                    key={index}
                    className="min-w-max px-3 py-2 rounded-xl bg-pale-grey ml-2 first:ml-0 z-10 text-greyish-brown text-xs"
                  >
                    {i?.name ?? ""}
                  </div>
                ))}
                {hiddenCategories > 0 && (
                  <div className="absolute top-0 right-0 z-20">
                    <div className="absolute top-0 right-0 w-20 h-8 bg-white-gradient z-10" />
                    <div className="absolute top-0 right-0 px-3 py-2 bg-pale-grey rounded-xl z-20 text-xs">
                      +{hiddenCategories}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isMobile && (
        <div
          className="absolute top-0 left-0 overflow-hidden flex flex-col justify-between z-0 opacity-0"
          ref={backImageWrapperRef}
          style={{
            width: cardWidth,
            height: cardHeight,
          }}
          onMouseEnter={glowBackImage.bind(null, 0)}
          onMouseLeave={hideBackImage.bind(null, 0)}
        >
          <div
            className="h-full absolute top-0"
            style={{ paddingRight: "168%", left: "-37%" }}
            ref={imageWrapper}
          >
            {isShowVideo && coverVideo ? (
              <video
                autoPlay
                muted
                playsInline
                className="absolute left-0 top-0 w-full h-full"
                src={coverVideo}
              />
            ) : (
              <Image
                layout="fill"
                src={headerImage}
                className="absolute left-0 top-0 w-full h-full"
                placeholder="blur"
                blurDataURL="/assets/sidemenu-logo/app-icon-1-1.png"
              />
            )}
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-profile-mask-gradient" />
          <div className="absolute bottom-0 left-0 w-full">
            <div className="w-full mx-3 mb-3 flex flex-col">
              <h3 className="text-lg font-bold line-clamp-1">{fullName}</h3>
              <h4 className="text-xs text-grey-80 line-clamp-1">
                {joinWithSeperator(" | ", job.join(", "), province)}
              </h4>
            </div>
            <div className="flex mx-3 mb-3">
              <div className="w-1/2 mx-1">
                <Button
                  infType="secondary"
                  onClick={(e) => onBackButtonClick(e, "chat")}
                >
                  <span className="w-5 h-5 mr-2">
                    <Image
                      src="/assets/sidemenu-logo/chat-icon.svg"
                      height={20}
                      width={20}
                    />
                  </span>
                  Chat
                </Button>
              </div>
              <div className="w-1/2 mx-1">
                <Button
                  infType="secondary"
                  onClick={(e) => onBackButtonClick(e, "call")}
                >
                  <span className="w-5 h-5 mr-2">
                    <Image
                      src="/assets/sidemenu-logo/contact-icon.svg"
                      height={20}
                      width={20}
                    />
                  </span>
                  Call
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
