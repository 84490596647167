import React from "react";
import Image from "next/image";
import { isMobile } from "react-device-detect";
import get from "lodash/get";
import difference from "lodash/difference";
import FilterSelect from "components/filter-select";
import MultiFilterSelect from "components/multi-filter-select";
import Tabs, { Tab } from "components/tab-panel";
import SortIcon from "components/icons/colored/Sort";
import { useAppSelector } from "store";
import {
  FILTER_FOLLOWER_OPTIONS,
  FILTER_PLATFORM_OPTIONS,
  HOMETOWN_OPTIONS,
  SORTING_OPTIONS,
} from "lib/constants";
import { useTracking } from "context/trackers";

interface ActionBoardProps {
  filter: FilterType;
  sort: string;
  handleFilterChange: (I: string, V: any) => void;
  setSort: React.Dispatch<React.SetStateAction<string>>;
}
const HomeActionBoard: React.FC<ActionBoardProps> = ({
  filter,
  sort,
  handleFilterChange,
  setSort,
}) => {
  const categoryOptions = useAppSelector((state) => state.category.list);
  const handleTabChange = (_: unknown, value: unknown) => {
    handleFilterChange("categories", [value as number]);
  };
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, [filter, sort]);

  return (
    <div className="top-container px-5 sm:p-0">
      <h2 className="hidden sm:block text-2xl text-dark-grey font-bold mb-2">
        Danh mục
      </h2>
      <div className="mt-2 sm:mt-0 w-full">
        <Tabs value={filter.categories[0]} onChange={handleTabChange}>
          <Tab key={"all"} label={"Tất cả"} value={"all"} />
          {categoryOptions.map((i) => (
            <Tab key={i.id} label={i.name} value={i.id} />
          ))}
        </Tabs>
      </div>
      <div className="w-full flex justify-between items-center mt-3">
        <div className="flex justify-start items-center">
          <div className="w-28 sm:w-44 mr-2 flex items-center">
            <MultiFilterSelect
              value={filter.platform}
              onChange={(e) =>
                handleFilterChange("platform", e.target.value as string)
              }
              options={FILTER_PLATFORM_OPTIONS}
              width={"13.25rem"}
              height={"unset"}
              inputProps={{
                startAdornment: (
                  <div className="ml-2 w-5 h-5 absolute left-0">
                    <Image
                      src="/assets/sidemenu-logo/layer-icon.svg"
                      alt="search icon"
                      height={20}
                      width={20}
                    />
                  </div>
                ),
              }}
            />
          </div>
          {!isMobile && (
            <div className="w-44 mr-2 flex items-center">
              <FilterSelect
                value={filter?.province?.[0] ?? "Tất cả"}
                onChange={(e) =>
                  handleFilterChange("province", [e.target.value as string])
                }
                options={HOMETOWN_OPTIONS_ALL}
                width={"11rem"}
                inputProps={{
                  startAdornment: (
                    <div className="ml-2 w-5 h-5 absolute left-0">
                      <Image
                        src="/assets/sidemenu-logo/location-icon.svg"
                        alt="search icon"
                        height={20}
                        width={20}
                      />
                    </div>
                  ),
                }}
              />
            </div>
          )}
          <div className="w-40 flex items-center">
            <MultiFilterSelect
              value={filter.no_followers}
              options={FILTER_FOLLOWER_OPTIONS}
              onChange={(e) =>
                handleFilterChange("no_followers", e.target.value as string)
              }
              width={"13.25rem"}
              height={"unset"}
              inputProps={{
                startAdornment: (
                  <div className="ml-2 w-5 h-5 absolute left-0">
                    <Image
                      src="/assets/sidemenu-logo/follower-icon.svg"
                      alt="search icon"
                      height={20}
                      width={20}
                    />
                  </div>
                ),
              }}
            />
          </div>
        </div>
        {!isMobile ? (
          <div className="w-56">
            <FilterSelect
              value={sort}
              onChange={(e) => setSort(e.target.value as string)}
              options={SORTING_OPTIONS}
              width={"12rem"}
              height={"unset"}
              inputProps={{
                startAdornment: (
                  <div className="ml-2 w-5 h-5 absolute left-0">
                    <Image
                      src="/assets/sidemenu-logo/sort-icon.svg"
                      alt="search icon"
                      height={20}
                      width={20}
                    />
                  </div>
                ),
              }}
            />
          </div>
        ) : (
          <div className="overflow-hidden p-2 bg-pale-grey h-9 w-9 flex justify-center items-center rounded-xl relative">
            <SortIcon style={{ fontSize: "1.25rem" }} />
            <select
              className="absolute w-9 h-9 opacity-0"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            >
              {SORTING_OPTIONS.map((i) => {
                return (
                  <option key={i.value} value={i.value}>
                    {i.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

interface SearchActionBoardProps extends ActionBoardProps {
  searchFor: string;
  totalResult: number;
}
const SearchActionBoard: React.FC<SearchActionBoardProps> = ({
  filter,
  handleFilterChange,
  searchFor,
  totalResult,
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, [filter]);
  return (
    <div className="top-container">
      <h2 className="text-grey-80 mb-8">
        {!searchFor ? (
          <span className="font-bold text-dark-grey">
            Hãy nhập tên KOL mà bạn muốn tìm kiếm
          </span>
        ) : (
          <>
            <span className="font-bold text-dark-grey">Kết quả tìm kiếm: </span>
            “{searchFor}”{" "}
            {totalResult && (
              <span>
                - Hiện có tất cả{" "}
                <span className="text-medium-blue">{totalResult} kết quả</span>
              </span>
            )}
          </>
        )}
      </h2>
      <div className="w-full flex justify-between mt-8">
        <div className="flex justify-start">
          <div className="w-44 mr-2">
            <MultiFilterSelect
              value={filter.platform}
              onChange={(e) =>
                handleFilterChange("platform", e.target.value as string)
              }
              options={FILTER_PLATFORM_OPTIONS}
              width={"13.25rem"}
              height={"unset"}
              inputProps={{
                startAdornment: (
                  <div className="ml-2 w-5 h-5 absolute left-0">
                    <Image
                      src="/assets/sidemenu-logo/layer-icon.svg"
                      alt="search icon"
                      height={20}
                      width={20}
                    />
                  </div>
                ),
              }}
            />
          </div>
          <div className="w-44 mr-2">
            <FilterSelect
              value={filter?.province?.[0] ?? "Tất cả"}
              onChange={(e) =>
                handleFilterChange("province", [e.target.value as string])
              }
              options={HOMETOWN_OPTIONS_ALL}
              width={"11rem"}
              inputProps={{
                startAdornment: (
                  <div className="ml-2 w-5 h-5 absolute left-0">
                    <Image
                      src="/assets/sidemenu-logo/location-icon.svg"
                      alt="search icon"
                      height={20}
                      width={20}
                    />
                  </div>
                ),
              }}
            />
          </div>
          <div className="w-48 mr-2">
            <MultiFilterSelect
              value={filter.no_followers}
              options={FILTER_FOLLOWER_OPTIONS}
              onChange={(e) =>
                handleFilterChange("no_followers", e.target.value as string)
              }
              width={"13.25rem"}
              height={"unset"}
              inputProps={{
                startAdornment: (
                  <div className="ml-2 w-5 h-5 absolute left-0">
                    <Image
                      src="/assets/sidemenu-logo/follower-icon.svg"
                      alt="search icon"
                      height={20}
                      width={20}
                    />
                  </div>
                ),
              }}
            />
          </div>
          {/* <div className="w-20"> */}
          {/*   <Button onClick={applyFilterHandler}>Áp dụng</Button> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export interface UseActionBoard {
  filter: FilterType;
  sort: string;
  handleFilterChange: (I: string, V: any[]) => void;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  HomeActionBoard?: any;
  SearchActionBoard?: any;
}

export interface ActionBoardOptions {
  defaultFilter?: FilterType;
  defaultSort?: string;
}

export type FilterType = {
  platform: string[];
  province: string[];
  no_followers: string[];
  categories: string[];
};

const HOMETOWN_OPTIONS_ALL = [
  { value: "all", label: "Tất cả địa điểm" },
  ...HOMETOWN_OPTIONS,
];

export const DEFAULT_FILTER: FilterType = {
  platform: ["all"],
  province: [HOMETOWN_OPTIONS_ALL[0].value],
  no_followers: ["all"],
  categories: ["all"],
};

const DEFAULT_SORT = "none";

const FILTER_DATA_HANDLE = {
  default: (_cur: unknown, i: unknown) => i,
  platform: (cur: string[], i: string[]) => {
    const check = difference(i, cur)[0];
    const uncheck = difference(cur, i)[0];
    if (check === "all") {
      return ["all"];
    }
    if (uncheck && i.length === 0) {
      return ["all"];
    }
    if (check) {
      return i.filter((s) => s !== "all");
    }
    return i;
  },
  no_followers: (cur: string[], i: string[]) => {
    const check = difference(i, cur)[0];
    const uncheck = difference(cur, i)[0];
    if (check === "all") {
      return ["all"];
    }
    if (uncheck && i.length === 0) {
      return ["all"];
    }
    if (check) {
      return i.filter((s) => s !== "all");
    }
    return i;
  },
};

export function useActionBoard({
  defaultFilter = { ...DEFAULT_FILTER },
  defaultSort = DEFAULT_SORT,
}: ActionBoardOptions) {
  const categoryOptions = useAppSelector((state) => state.category.list);
  const [filter, setFilter] = React.useState<FilterType>(defaultFilter);
  const [sort, setSort] = React.useState<string>(defaultSort);
  const mountedRef = React.useRef(false);
  const { logEvent } = useTracking();
  const handleFilterChange = (props: string, value: any) => {
    setFilter((i) => {
      const cur = get(i, props);
      const handler = get(
        FILTER_DATA_HANDLE,
        [props],
        get(FILTER_DATA_HANDLE, "default")
      );
      const v = handler(cur, value);
      /* Tracking GA for Filter change*/
      let trackingLabel = v;
      if (props === "category") {
        trackingLabel = get(
          categoryOptions.filter((i) => i.id === v),
          "name",
          v
        );
      }
      logEvent({ category: "Filter", action: `${props} ${trackingLabel}` });
      /* End Tracking GA for Filter change*/
      return {
        ...i,
        [props]: v,
      };
    });
  };

  React.useEffect(() => {
    if (mountedRef.current) {
      logEvent({ category: "Sort", action: sort });
    }
  }, [sort]);

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  return {
    filter,
    sort,
    handleFilterChange,
    setSort,
    HomeActionBoard,
    SearchActionBoard,
  } as UseActionBoard;
}
