import React, { useCallback, useEffect } from "react";
import withReduxSaga from "next-redux-saga";
import { useAppDispatch, useAppSelector, wrapper } from "store";
import ReactDOM from "react-dom";
import Backdrop from "@material-ui/core/Backdrop";
import MuiModal from "@material-ui/core/Modal";
import Skeleton from "@material-ui/lab/Skeleton";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import ModalBody from "components/modal-body";
import CopyableInput from "components/copyable-input";
import MailIcon from "components/icons/Mail";
import PhoneIcon from "components/icons/Phone";
import ThemeProvider from "context/ThemeProvider";
import { getSensityInfoAsync } from "features/profile/profileSlice";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  iconError: {
    marginBottom: "0.9375rem",
    fontSize: "4.375rem",
    color: "#FF0000",
  },
  iconSuccess: {
    marginBottom: "0.9375rem",
    fontSize: "4.375rem",
    color: "#FF0000",
  },
});

interface PopupProps {
  visible: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  afterClose: () => void;
  influzeeID: string;
  fullName: string;
  avatar: string;
  phone: string;
  email: string;
}

const Modal: React.FC<PopupProps> = ({
  visible,
  onOpen,
  onClose,
  afterClose,
  influzeeID,
  avatar,
  fullName,
  phone,
  email,
}) => {
  const dispatch = useAppDispatch();
  const sensityInfo = useAppSelector(
    (state) => state.profile.sensityInfos[influzeeID]
  );
  const handleClose = useCallback(() => {
    if (onClose && typeof onClose === "function") {
      onClose();
    }
    if (afterClose && typeof afterClose === "function") {
      afterClose();
    }
  }, []);

  useEffect(() => {
    if (visible) {
      if (onOpen && typeof onOpen === "function") {
        onOpen();
      }
    }
  }, [visible]);

  useEffect(() => {
    if (!sensityInfo) {
      // TODO dispatch action to saga
      dispatch(getSensityInfoAsync(influzeeID));
    }
  }, []);
  const classes = useStyles();
  return (
    <ThemeProvider>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Fade in={visible}>
          <ModalBody
            showCloseIcon
            className="bg-white p-5 pt-7 rounded-xl relative"
            size="s"
          >
            <div className="flex items-center justify-center flex-col">
              <div className="w-44 h-44 relative mb-5">
                <div
                  className="w-full h-auto relative"
                  style={{ padding: "100% 0 0 0" }}
                >
                  <img
                    className="absolute top-0 left-0 w-full h-auto rounded-xl filter drop-shadow-xl"
                    src={avatar}
                    alt="Avatar"
                  />
                </div>
              </div>
              <div className="text-sm text-grey-80 text-center mb-1">
                Để chúng tôi giúp bạn liên hệ với
              </div>
              <div className="text-lg text-center font-bold mb-5">
                {fullName}
              </div>
              {sensityInfo ? (
                <>
                  {sensityInfo.contactEmail && (
                    <div className="w-full mb-2">
                      <CopyableInput
                        text={sensityInfo.contactEmail}
                        prefixIcon={
                          <MailIcon style={{ fontSize: "1.25rem" }} />
                        }
                        inputClassName="w-40"
                      />
                    </div>
                  )}
                  {sensityInfo.contactPhone && (
                    <div className="w-full mb-2">
                      <CopyableInput
                        text={sensityInfo.contactPhone}
                        prefixIcon={
                          <PhoneIcon style={{ fontSize: "1.25rem" }} />
                        }
                        inputClassName="w-40"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="w-full mb-2">
                    <Skeleton variant="text" width="100%" height="36px" />
                  </div>
                  <div className="w-full mb-2">
                    <Skeleton variant="text" width="100%" height="36px" />
                  </div>
                </>
              )}
            </div>
          </ModalBody>
        </Fade>
      </MuiModal>
    </ThemeProvider>
  );
};
type DialogConfig = Omit<PopupProps, "visible" | "close" | "afterClose">;

type ConfigUpdate = DialogConfig | ((prevConfig: DialogConfig) => DialogConfig);

const ModalWithStore = wrapper.withRedux(withReduxSaga(Modal));
const OpenContactInfo = (config: DialogConfig) => {
  const div = document.createElement("div");
  document.body.appendChild(div);
  let currentConfig: any = {
    ...config,
    close,
    visible: true,
    afterClose: destroy,
  };

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  function render({ ...props }: any) {
    ReactDOM.hydrate(<ModalWithStore {...props} />, div);
  }

  function close() {
    currentConfig = {
      ...currentConfig,
      visible: false,
    };
    render(currentConfig);
  }

  function update(configUpdate: ConfigUpdate) {
    if (typeof configUpdate === "function") {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate,
      };
    }
    render(currentConfig);
  }

  const closeModal = () => {
    close();
    destroy();
  };

  render(currentConfig);
  return {
    close: closeModal,
    update,
  };
};

export default OpenContactInfo;
