import { useSWRInfinite } from "swr";
import get from "lodash/get";
import { getProfileList } from "./api";
import { IProfile } from "./type";

export const PAGE_SIZE = 36;
const getList = async (
  _: string,
  filter: string,
  sort: string,
  page: number,
  perPage: number
) => {
  const { profiles = [], total = 0 } = await getProfileList(
    JSON.parse(filter),
    JSON.parse(sort),
    page,
    perPage
  );
  return {
    profiles: profiles.map((i) => new IProfile(i)),
    total,
  };
};

const _stringToSort = (v: string) => {
  if (v === "none") {
    return {};
  }
  const regexV = /(^-?)([a-z_]*$)/.exec(v);
  if (regexV && regexV[2]) {
    return {
      [regexV[2]]: regexV[1] ? 1 : -1,
    };
  }
  return {};
};

const _removeAll = (
  o: Record<string, string | string[] | (string | number)[]>
) => {
  return Object.keys(o).reduce((acc, i) => {
    if (typeof o[i] === "string") {
      if (o[i]) {
        return {
          ...acc,
          [i]: o[i],
        };
      } else {
        return acc;
      }
    }
    const temp1 = (o[i] as string[] | (string | number)[]).filter(
      (i) => i !== "all"
    );
    if (temp1 && temp1.length > 0) {
      return {
        ...acc,
        [i]: temp1,
      };
    }
    return acc;
  }, {});
};

export function useProfileList(
  filter: Record<string, string | string[] | (string | number)[]>,
  sort: string,
  initialSize = 1
) {
  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
    (page, previousPageData) => {
      const profiles = get(previousPageData, "profiles", []);
      if (previousPageData && profiles.length < PAGE_SIZE) return null;
      return [
        `/profile/list`,
        JSON.stringify(_removeAll(filter)),
        JSON.stringify(_stringToSort(sort)),
        page + 1,
        PAGE_SIZE,
      ];
    },
    getList,
    {
      initialSize,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error.status === 500) return;
        if (retryCount >= 10) return;
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
      revalidateAll: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const profiles: IProfile[] = data
    ? ([] as IProfile[]).concat(...data.map((i) => i.profiles))
    : [];
  const isLoading = !data && !error;
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");
  const isEmpty = profiles?.length === 0;
  let isReachingEnd = false;
  if (data) {
    const lastPageProfile = get(data, [data.length - 1, "profiles"], []);
    isReachingEnd = isEmpty || lastPageProfile.length < PAGE_SIZE;
  }
  const total = get(data, [0, "total"], 0);
  const isRefreshing = isValidating && data && data.length === size;
  return {
    profiles,
    isLoading,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    isRefreshing,
    mutate,
    error,
    size,
    setSize,
    total,
  };
}
