import React from "react";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from "@material-ui/core/MenuItem";
import InputBase from "./input-base";
import Select, { Props as SelectProps } from "./select";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props extends SelectProps {
  defaultValue?: string[];
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface ItemProps extends Omit<MuiMenuItemProps, "button"> {}

const useMenuItemStyle = makeStyles({
  root: {
    position: "relative",
    "&:focus": {
      background: "#f5f7fa",
      "& .MuiListItemIcon-root": {
        color: "#808080",
      },
      "& .MuiListItemText-primary": {
        color: "#808080",
      },
    },
    "&.Mui-selected": {
      background: "#f5f7fa",
    },
    "&:hover": {
      background: "#f5f7fa",
    },
  },
});

const SelectItem = React.forwardRef<HTMLLIElement, ItemProps>(
  ({ ...others }, ref) => {
    const classes = useMenuItemStyle();
    return (
      <MuiMenuItem ref={ref} classes={{ root: classes.root }} {...others} />
    );
  }
);

SelectItem.displayName = "SelectItem";

const MultiFilterSelect: React.FC<Props> = ({
  options,
  multiple,
  value = [],
  onChange,
  inputProps,
  ...others
}) => {
  const [_optionsPair] = React.useState<{ [key: string]: string }>(() => {
    const result: { [key: string]: string } = {};
    options.forEach((i) => {
      result[i.value] = i.label;
    });
    return result;
  });
  const onChangeHandler = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    n: React.ReactNode
  ) => {
    const onChangeInput = get(others, "inputProps.onChange");
    if (onChangeInput && typeof onChangeInput === "function") {
      onChangeInput(event);
    }
    if (onChange && typeof onChange === "function") {
      onChange(event, n);
    }
  };

  return (
    <Select
      multiple
      disableNative
      options={options}
      value={value}
      onChange={onChangeHandler}
      renderValue={(selected) =>
        (selected as string[]).map((i) => get(_optionsPair, i)).join(", ")
      }
      renderChild={(option) => {
        return (
          <SelectItem key={option.value} value={option.value}>
            {(value as string[]).indexOf(option.value) > -1 ? (
              <CheckCircleOutlineIcon
                className="mr-2"
                style={{ fontSize: "1rem", color: "#01334c" }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                className="mr-2"
                style={{ fontSize: "1rem", color: "rgba(0,0,0,.15)" }}
              />
            )}
            <span className="text-grey-80 text-sm">{option.label}</span>
            {/* <Checkbox checked={_value.indexOf(option.value) > -1} /> */}
            {/* <ListItemText primary={option.label} /> */}
          </SelectItem>
        );
      }}
      input={<InputBase {...inputProps} />}
      {...others}
    />
  );
};

export default MultiFilterSelect;
