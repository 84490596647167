import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import MuiInputBase, {
  InputBaseProps as MuiInputBaseProps,
} from "@material-ui/core/InputBase";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props extends MuiInputBaseProps {
  height?: string;
  errorMsg?: string;
  disableErrorMsg?: boolean;
}

const useInputStyles = makeStyles({
  input: {
    color: "#4d4d4d",
    background: "#f5f7fa",
    borderRadius: "0.75rem",
    border: "1px solid transparent",
    fontSize: "0.875rem",
    height: "2.375rem",
    "& .MuiInputBase-input": {
      paddingLeft: "2.4rem",
      paddingBottom: "0.375rem",
      paddingTop: "0.375rem",
      paddingRight: "1.5rem",
    },
    "&:hover": {
      background: "#f5f7fa",
    },
    "&:focus": {
      background: "#f5f7fa",
    },
    "&:acitve": {
      background: "#f5f7fa",
    },
    "&.Mui-focused": {
      background: "#f5f7fa",
    },
    "&.Mui-error": {
      borderColor: "#f53126",
    },
    "&.Mui-disabled": {
      background: "#f5f7fa",
    },
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
  disabled: {
    opacity: "0.5",
    background: "#f5f7fa",
  },
});

const InputBase: React.FC<Props> = ({
  height,
  errorMsg,
  disableErrorMsg,
  ...others
}) => {
  const classes = useInputStyles();
  return (
    <>
      <MuiInputBase
        fullWidth
        classes={{ root: classes.input, disabled: classes.disabled }}
        style={{ height }}
        error={!!errorMsg}
        {...others}
      />
      {errorMsg && !disableErrorMsg && (
        <Fade in={!!errorMsg && !disableErrorMsg}>
          <div className="pl-5 mt-1 text-xs text-error-red">{errorMsg}</div>
        </Fade>
      )}
    </>
  );
};

export default InputBase;
